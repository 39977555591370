.job-code {
	display: inline-block;
	background: #ddd;
	padding: 4px 5px;
	border-radius: 3px;
	color: white;
	font-size: 0.8em;
	text-transform: uppercase;

	/* /Jessica Cassel */
	&.account-exec-76 {
		background: black;
		color: white;
	}
	/* Jatan Dugar */
	&.account-exec-70 {
		background: #10ff00;
		color: black;
	}
	/* /Brian Setzer */
	&.account-exec-18 {
		background: #00ffff;
		color: black;
	}
	/* Nadare Izadi */
	&.account-exec-43 {
		background: #ffff00;
		color: black;
	}
	/* Luke Silver-Greenberg */
	&.account-exec-77 {
		background: #ff00ff;
		color: black;
	}
}
