.versions-index {
}
.versions-show {
	> md-card {
		h2 {
			margin: 5px 0px 5px 0px;
			margin-left: 0px;
		}

		p {
			margin: 0;
			margin-left: 15px;
		}

		.nested-card {
			md-card-content {
				padding: 0;
				margin-left: 15px;
			}
			.md-title {
				margin: 0;
			}
		}
	}
}

.versions-new {
	label {
		margin-left: 4px;
	}
}

.versions-presentation {
}
