.page-dashboard-admin {
	.less-important {
		font-size: 0.8em;
		opacity: 0.5;
	}

	md-card-header {
		border-bottom: 1px solid #ddd;
	}
}
