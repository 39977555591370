@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/angular-material/angular-material.css';
@import './app/**/!(index|_)*.css';

@tailwind base; /* remember to have preflight: false in tailwind config corePlugins */
@tailwind components;

:root {
	--md-grey-50: #fafafa;
	--md-grey-100: #f5f5f5;
	--md-grey-200: #eeeeee;
	--md-grey-300: #e0e0e0;
	--md-grey-400: #bdbdbd;
	--md-grey-500: #9e9e9e;
	--md-grey-600: #757575;
	--md-grey-700: #616161;
	--md-grey-800: #424242;
	--md-grey-900: #212121;
	--md-orange-50: #ffe2c7;
	--md-orange-100: #ffcfa2;
	--md-orange-200: #ffbb7a;
	--md-orange-300: #ffac5e;
	--md-orange-400: #ff9b4d;
	--md-orange-500: #f68c43;
	--md-orange-600: #e68233;
	--md-orange-700: #d77325;
	--md-orange-800: #d06613;
	--md-orange-900: #b55000;
	--bond-orange: #f68c43;
	--toolbar-height: 64px;
	--header-height: 168px;
	--active: #cf9;
	--pending: #fe9;
}

body {
	/* Helvetica is broken on the office computers */
	font-family: Avenir, Arial, Helvetica, sans-serif;
}

a {
  text-decoration: underline;
}

.add-new md-icon {
	color: white !important;
}

.md-whiteframe-1dp {
	box-shadow:
		0px 1px 3px 0px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.md-dialog-is-showing {
	overflow: hidden;
}

[ui-view='modal'] {
	transition: 0.3s all;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 20;
	background: rgba(0, 0, 0, 0.5);
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

* {
	box-sizing: border-box;
}

body {
	font-size: 14px;
}

input {
  border-radius: 0px;
}

.date_validation_box input {
	display: none;
}

[ui-sref],
[ng-click] {
	user-select: none;
	cursor: pointer;
	outline: 0;
}

html,
body,
.page-wrapper {
	background-color: rgb(245, 245, 245);
}

.bg-white {
	background-color: white;
}

/* //navbar */
.page-wrapper {
	.main-nav {
		position: fixed;
		z-index: 3;
	}
	.wrapper-progress-bar {
		position: fixed;
		margin-top: 56px;
	}
}

.margin-10 {
	margin: 10px;
}
.margin-15 {
	margin: 15px;
}
.margin-20 {
	margin: 20px;
}
.margin-25 {
	margin: 25px;
}

th {
	text-align: left;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.all-caps {
	text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 1rem;
	margin-top: 1rem;
}

table.simple {
	width: 100%;
	border: none;
	border-spacing: 0;
	text-align: left;
	background-color: white;

	thead tr th {
		padding: 16px 8px;
		font-weight: 500;
		color: var(--md-grey-600);
		border-bottom: 1px solid var(--md-grey-200);
		white-space: nowrap;
		min-width: 120px;
		text-align: left;
		&.actions {
			width: 60px;
			text-align: right;
		}
		&.active {
			font-weight: bold;
		}

		.sort-arrow {
			height: 18px;
			line-height: 18px;
		}
	}

	tbody tr {
		&.selected {
			background: #e3f2fd;
		}
		td {
			padding: 16px 8px;
			border-bottom: 1px solid var(--md-grey-100);
			&.actions {
				width: 60px;
				text-align: right;
			}
		}
	}

	&.has-fab {
		tbody tr td,
		thead tr th {
			&:first-child {
				border-bottom: 0px solid transparent;
				width: 110px;
				min-width: 110px;
			}
		}
	}

	&.hover-rows {
		tbody tr:hover {
			background: #e3f2fd;
			td {
				border-bottom: 1px solid #a8cee9;
			}
		}
	}
}

.main-nav {
	.logo {
		max-height: 26px;
		cursor: pointer;
	}
}

.padded {
	padding: 10px;
}
.padded-v {
	padding: 10px 0px 10px 0px;
}
.padded-h {
	padding: 0px 10px 0px 10px;
}
.padded-t {
	padding-top: 10px;
}
.padded-b {
	padding-bottom: 10px;
}

.padded-less {
	padding: 5px;
}
.padded-less-v {
	padding: 5px 0px 5px 0px;
}
.padded-less-h {
	padding: 0px 5px 0px 5px;
}
.padded-less-t {
	padding-top: 5px;
}
.padded-less-b {
	padding-bottom: 5px;
}

.padded-more {
	padding: 15px;
}
.padded-more-v {
	padding: 15px 0px 15px 0px;
}
.padded-more-h {
	padding: 0px 15px 0px 15px;
}
.padded-more-t {
	padding-top: 15px;
}
.padded-more-b {
	padding-bottom: 15px;
}

.padded-most {
	padding: 20px;
}
.padded-most-v {
	padding: 20px 0px 20px 0px;
}
.padded-most-h {
	padding: 0px 20px 0px 20px;
}
.padded-most-t {
	padding-top: 20px;
}
.padded-most-b {
	padding-bottom: 20px;
}

.no-margin {
	margin: 0;
}
.margin {
	margin: 10px;
}
.margin-v {
	margin: 10px 0px 10px 0px;
}
.margin-h {
	margin: 0px 10px 0px 10px;
}
.margin-t {
	margin-top: 10px;
}
.margin-b {
	margin-bottom: 10px;
}
.margin-r {
	margin-right: 10px;
}
.margin-l {
	margin-left: 10px;
}

.margin-less {
	margin: 5px;
}
.margin-less-v {
	margin: 5px 0px 5px 0px;
}
.margin-less-h {
	margin: 0px 5px 0px 5px;
}
.margin-less-t {
	margin-top: 5px;
}
.margin-less-b {
	margin-bottom: 5px;
}
.margin-less-r {
	margin-right: 5px;
}
.margin-less-l {
	margin-left: 5px;
}

.margin-more {
	margin: 15px;
}
.margin-more-v {
	margin: 15px 0px 15px 0px;
}
.margin-more-h {
	margin: 0px 15px 0px 15px;
}
.margin-more-t {
	margin-top: 15px;
}
.margin-more-b {
	margin-bottom: 15px;
}
.margin-more-r {
	margin-right: 15px;
}
.margin-more-l {
	margin-left: 15px;
}

.margin-most {
	margin: 20px;
}
.margin-most-v {
	margin: 20px 0px 20px 0px;
}
.margin-most-h {
	margin: 0px 20px 0px 20px;
}
.margin-most-t {
	margin-top: 20px;
}
.margin-most-b {
	margin-bottom: 20px;
}
.margin-most-r {
	margin-right: 20px;
}
.margin-most-l {
	margin-left: 20px;
}

.width-1\/1 {
	width: 100%;
}
.width-1\/2 {
	width: 50%;
}
.width-1\/3 {
	width: 33.333%;
}
.width-1\/4 {
	width: 25%;
}

.max-width-1\/1 {
	max-width: 100%;
}
.max-width-1\/2 {
	max-width: 50%;
}
.max-width-1\/3 {
	max-width: 33.333%;
}
.max-width-1\/4 {
	max-width: 25%;
}

.bold {
	font-weight: bold;
}

.server-errors {
	background: lightpink;
	padding: 10px;
	border: 1px solid #999;
}

md-toast.md-error-theme {
	.md-toast-content {
		background-color: #900;
	}
}

.day-tag {
	background: #777;
	border-radius: 5px;
	color: white;
	font-size: bold;
	font-weight: 900;
	font-size: 0.7em;
	padding: 3px 4px;
	margin: 0 1em;
	white-space: nowrap;

	&.day-tag-rejected {
		background: maroon;
	}

	&.day-tag-review {
		background: dodgerblue;
	}

	&.day-tag-resubmitted {
		background: green;
	}

	&.day-tag-approved {
		background: darkgreen;
	}

	&.day-tag-ot-status_pending {
		background: purple;
	}
	&.day-tag-ot-status_approved {
		background: green;
	}
	&.day-tag-ot-status_rejected {
		background: red;
	}
}

@media print {
	body {
		font-size: 12px;
	}
	.no-print,
	.no-print * {
		display: none !important;
	}
	.print-pane {
		display: block !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 100% !important;
		max-width: 100% !important;
		flex: 1 !important;
		img {
			max-width: 100% !important;
		}
	}
}

@tailwind utilities;
