.page-dashboard-manager {
	.less-important {
		font-size: 0.8em;
		opacity: 0.5;
	}

	md-card-header {
		border-bottom: 1px solid #ddd;
	}

	.data-row {
		td {
			border-bottom: none;
			padding-bottom: 4px;
		}
	}
}
