project-spend-chip {
	.project-spend-chip {
		padding: 3px;
		border-radius: 5px;
		border: 1px solid #333;
		font-weight: bold;

		&.lt-50 {
			background: #d5fa7d;
		}
		&.lt-40 {
			background: #ffff9f;
		}
		&.lt-30 {
			background: #fade58;
		}
		&.lt-20 {
			background: #faa358;
		}
		&.lt-10 {
			background: #fa5f58;
		}
		&.lt-0 {
			background: #ff241a;
		}
	}
}
