.page-dashboard-user.week-hours {
	padding-bottom: 50px;
	background: white;
	border-bottom: 1px solid #e8e8e8;

	td.week-cell {
		position: relative;
		vertical-align: top;
		text-align: left;
		table tbody tr & {
			padding: 10px 10px;
		}
		md-divider {
			margin-top: 4px;
			margin-bottom: 4px;
		}
		.total {
			/*text-align: center;*/
			font-size: 1.1em;
			font-weight: bold;
			text-align: left;
			margin-bottom: 15px;
		}
		.name,
		.job_code,
		.deliverable,
		.version,
		.task,
		.cost,
		.hours,
		.memo {
			color: #666;
		}
		.hours {
			color: #999;
		}

		&.locked {
			background: whitesmoke;
		}
		.project-row {
			padding-bottom: 10px;
		}

		.submit {
			position: absolute;
			top: 100%;
		}

		&.locked {
			.submit {
				display: none;
			}
		}
	}

	.week-table th {
		.locked-icon {
			md-icon {
				font-size: 20px;
				color: #b7b7b7;
			}
			text-align: center;
			padding: 0 10px;
			display: none;
		}

		&.locked {
			.locked-icon {
				display: inline-block;
			}
		}
	}
}
