.projects-index {
}
.projects-show {
	> md-card {
		h2 {
			margin: 5px 0px 5px 0px;
			margin-left: 0px;
		}

		p {
			margin: 0;
			margin-left: 15px;
		}

		.nested-card {
			md-card-content {
				padding: 0;
				margin-left: 15px;
			}
			.md-title {
				margin: 0;
			}
		}
	}
}

.projects-edit,
.projects-new {
	label {
		margin-left: 4px;
	}
	.chips-selector-area {
		overflow: hidden;
		overflow-y: scroll;
		max-height: 230px;

		.no-picture,
		.contact-item {
			box-sizing: border-box;
			&.selected {
				opacity: 0.5;
			}
			&.selected h3 {
				opacity: 0.5;
			}
			.md-list-item-text h3 {
				margin: 0 !important;
				padding: 0;
				line-height: 1.2em !important;
			}
			.md-list-item-text h3,
			.md-list-item-text p {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			@media screen and (min-width: 960px) {
				float: left;
				width: 33%;
			}
		}

		.contact-item {
			.md-list-item-text {
				padding: 14px 0;
				max-width: 190px;
			}
		}
	}
}

.projects-presentation {
}
