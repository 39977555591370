.page-reports-hours {
	&.page-dashboard-user.week-hours {
		background: none;
	}

	md-card-header {
		border-bottom: 1px solid #ddd;
	}

	thead tr th,
	td {
		color: #454545;
		border-left: 1px solid #eee;
		border-bottom: none;

		.project-row {
			padding: 10px;
			margin-bottom: 20px;
		}

		&.empty {
			background-color: #fbf2b1;
			color: #b3ac7c;
			font-style: italic;
		}

		&.locked {
			background-color: whitesmoke;
			&.empty {
				background-color: whitesmoke;
				color: #ccc;
				font-style: italic;
			}
		}
	}

	table.timesheets-table {
		tr.user-row {
			&.reportee {
				td:nth-child(1) {
					font-weight: bold;
				}
			}
		}
	}

	.chart-container {
		text-align: center;
		width: 600px;
		height: auto;
		margin: 10px auto;
		.chart-title {
			margin: 20px 0;
		}
	}

	.autocomplete-field {
		md-input-container {
			padding: 0 !important;
			margin: 0 !important;
		}
	}

	.budget-bar {
		position: relative;
		width: 100%;
		height: 2em;
		overflow: hidden;
		background-color: #ccc;

		.budget-used {
			height: 2em;
			background-color: #1574ab;

			&.warn {
				background-color: #c99c00;
			}
			&.danger {
				background-color: #a20000;
			}
		}
	}
}

.page-reports-form .reportsForm {
	md-autocomplete[md-floating-label] md-input-container {
		padding-bottom: 0px;
	}
	label {
		text-transform: capitalize;
	}
}
