project-thermometer {
	.thermometer {
		height: 9px;
		padding: 0;
		overflow: visible;
		line-height: 0;
		border: 1px solid black;
		border-radius: 2px;
		background: linear-gradient(90deg, #8bfd5c, #ff0000);

		.remaining-mask {
			float: right;
			background: white;
			right: 0;
			height: 100%;
		}
	}
}
