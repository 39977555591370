.page-header-warning-bar {
	background-color: var(--bond-orange);
	padding: 12px;
	color: black;
}

/* Page Header */
.fab-indented {
  padding-left: 90px;
}
@screen lg {
  .lg\:fab-indented {
    padding-left: 90px;
  }
}

.page-header {
	position: relative;
	height: 120px;
	padding: 12px 16px;
	background-image: url(../../assets/img/header-bg.jpg);
	background-size: cover;
	background-position: center center;
	background-color: #999;
  @screen lg {
    padding: 24px;
  }

	.page-header-content {
		min-height: 100%;
		color: white;
		.logo-area {
			color: white;
			.logo-icon {
			}
			.logo-text {
				font-size: 24px;
			}
		}

		.s24 {
			font-size: 24px;
			line-height: 24px;
			margin-right: 10px;
		}

		.whitebox {
			background-color: white;
			.s24 {
				margin-left: 10px;
			}
		}

		.md-button .s24 {
			margin: 10px;
		}

		.tab-filter-links {
			color: var(--md-grey-200);
			> * {
				padding-left: 5px;
				padding-right: 5px;
			}
			.tab-filter-link {
				color: var(--md-grey-200);
				&.active {
					color: white;
					font-weight: bold;
				}
			}
		}

		.project-link {
			/*font-size: 20px;*/
			color: white;
		}

		.header-dropdown {
			color: black;
			background: white;
			padding: 8px;
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: 3px;
			.md-errors-spacer {
				display: none;
			}
			md-input-container {
				margin: 0;
			}
		}
	}

	.add-new {
		position: absolute;
		bottom: -32px;
		left: 16px;
		z-index: 2;
	}
}

.main-nav {
	z-index: 100;
	.md-avatar-button.md-icon-button {
		padding: 0;
		margin-right: 4px;
		> img {
			max-width: 100%;
		}
	}
}

.sticky-top-nav {
	position: sticky;
	top: 64px;
	z-index: 1;
}

.sidenav-menu {
	md-list-item.active {
		color: var(--bond-orange);
		md-icon {
			color: var(--bond-orange);
		}
	}
}

.sidenav-notifications {
	md-list md-list-item.md-3-line.md-long-text {
		margin: 0;
		> .md-no-style {
			padding: 16px;
		}

		&.unread {
		}

		&.read {
			background-color: #eee;
			opacity: 0.75;
			font-style: italic;
		}

		.md-list-item-text {
			p {
				margin-top: 5px;
				line-height: 1;
			}
		}
	}
}

.overall-content-wrapper {
	padding-top: 56px;
	.md-button.md-warn.md-fab {
		background-color: var(--bond-orange);
		&[disabled] {
			background-color: rgba(0, 0, 0, 0.12);
		}
		&:not([disabled]):hover {
			background-color: var(--md-orange-600);
		}
	}
}

.md-dialog-content {
	.modal-link-indicator {
		margin: 0;
		padding: 0;
		height: auto;
		width: auto;
	}
}

.api-errors {
	padding-top: 10px;
	.field-name {
		color: #900;
	}
	ul {
		padding-left: 30px;
		margin: 0;
	}
}
