.page-dashboard-user {
	.less-important {
		font-size: 0.8em;
		opacity: 0.5;
	}

	.hourForm {
		md-autocomplete[md-floating-label] md-input-container {
			padding-bottom: 0px;
		}
		label {
			text-transform: capitalize;
		}
	}

	.inline-input {
		border-top: 0px solid transparent;
		border-left: 0px solid transparent;
		border-right: 0px solid transparent;
		border-bottom: 1px solid #999;
		background-color: transparent;
		&:focus {
			border-bottom: 2px solid var(--md-orange-500);
			outline: 0;
		}
	}

	.hours-empty {
		color: #999;
	}
}
