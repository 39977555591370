.page-home {
	.main-card {
		max-width: 1200px;
		.md-card-image {
			max-width: 100%;
			display: block;
		}
		.image-wrapper {
			position: relative;
			.action-button {
				position: absolute;
				bottom: 0;
				width: 100%;
				text-align: center;
			}
		}
	}
}
