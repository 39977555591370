.modal__wrapper {
	z-index: 20;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.modal__backdrop {
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&.modal__backdrop--dark {
		background-color: rgba(0, 0, 0, 0.48);
	}
}

.modal__window {
	position: relative;
	z-index: 11;
	overflow: auto;
	box-shadow:
		0px 7px 8px -4px rgba(0, 0, 0, 0.2),
		0px 13px 19px 2px rgba(0, 0, 0, 0.14),
		0px 5px 24px 4px rgba(0, 0, 0, 0.12);
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	color: rgba(0, 0, 0, 0.87);
	max-width: 100%;
	max-height: 100%;

	/* Preserve material dialog styles for now */
	md-dialog-content {
		order: 1;
		flex-direction: column;
		overflow: auto;
	}

	.md-dialog-content {
		padding: 24px;
	}

	md-dialog-actions {
		display: flex;
		order: 2;
		box-sizing: border-box;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 0;
		padding-right: 8px;
		padding-left: 16px;
		min-height: 52px;
		overflow: hidden;
	}
}
