.helpers {
	.helpers-tooltip-button {
		min-height: 0;
		height: auto;
		min-width: 0;
		width: auto;
		margin: 0;
		padding: 0;

		md-icon {
			font-size: 20px;
			height: auto;
		}
	}
}
